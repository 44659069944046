import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as pdfjs from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.mjs';
import styles from './pdf-editor-component.module.scss';
import PdfPageSelect from '../pdf-page-select/pdf-page-select';
import getSvg from "../../../../../utils/getSvg";
import ImageTypeSelector from "../image-type-select/image-type-select";
import PannablePhoto from "./pannable-photo";
import { ReactComponent as WatchIcon } from '../../../../../images/icon-watch.svg';
import { ReactComponent as MarkerPinIcon } from '../../../../../images/icon-marker-pin-01.svg';

export const PdfEditorComponent = ({
                                     pdfBlob,
                                     pins,
                                     setPins,
                                     currentImage,
                                     viewType,
                                     editImage,
                                     selectedPages = [],
                                     activeTab,
                                     onPinAdd,
                                     onPinRemove
                                   }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(selectedPages[0]?.pageNumber ?? 1);
  const [zoom, setZoom] = useState(viewType === 'edit' ? 1 : 1.25);
  const [selectedPin, setSelectedPin] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [startPan, setStartPan] = useState({ x: 0, y: 0 });
  const [currentOffset, setCurrentOffset] = useState({ x: 0, y: 0 });
  const [activeType, setActiveType] = useState('IMAGE');

  const zoomIncrement = 0.25;
  const minZoom = 0.75;
  const maxZoom = 5;

  useEffect(() => {
    const loadPdf = async () => {
      if (pdfBlob) {
        if (pdf) await pdf.destroy();
        const arrayBuffer = await pdfBlob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const loadingTask = pdfjs.getDocument({ data: uint8Array });
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
      }
    };
    loadPdf();
  }, [pdfBlob]);

  const renderPage = useCallback(async () => {
    if (pdf) {
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: zoom });
      const canvas = canvasRef.current;
      if (canvas) {
        const canvasContext = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const renderContext = { canvasContext, viewport };
        await page.render(renderContext).promise;
      }
    } else if (editImage) {
      const img = new Image();
      img.src = editImage;
      img.onload = () => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        if (canvas && container) {
          const context = canvas.getContext('2d');
          canvas.width = img.width * zoom;
          canvas.height = img.height * zoom;
          context?.clearRect(0, 0, canvas.width, canvas.height);
          context?.drawImage(img, 0, 0, img.width * zoom, img.height * zoom);
        }
      };
    }
  }, [pdf, pageNumber, zoom, editImage]);

  useEffect(() => {
    renderPage();
  }, [renderPage]);

  const resetZoom = () => setZoom(1);
  const resetOffset = () => setCurrentOffset({ x: 0, y: 0 });

  const handlePageChange = (page) => {
    setPageNumber(page);
    resetZoom();
    resetOffset();
  };

  const handleCanvasClick = (e) => {
    if (viewType !== 'edit' || !currentImage) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;
    const newPin = {
      x,
      y,
      page: pageNumber,
      dataType: activeTab,
      img: `https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com/${currentImage.Key}`,
      id: Date.now().toString()
    };
    onPinAdd(newPin);
  };

  const navigateImages = (direction) => {
    const currentPagePins = (pins[pageNumber] || []).filter(p => p.dataType.match(activeType));
    const currentIndex = currentPagePins.findIndex(pin => pin.id === selectedPin?.id);
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % currentPagePins.length;
    } else {
      newIndex = (currentIndex - 1 + currentPagePins.length) % currentPagePins.length;
    }
    const pin = currentPagePins[newIndex];
    setSelectedPin(pin);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + zoomIncrement, maxZoom));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - zoomIncrement, minZoom));
  };

  const handlePinClick = (pin) => {
    setSelectedPin(pin);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedPin(null);
  };

  const handleRemovePin = () => {
    if (selectedPin) {
      onPinRemove(selectedPin);
      closeModal();
    }
  };

  const handleMouseDown = (e) => {
    setIsPanning(true);
    setStartPan({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isPanning) return;
    const dx = e.clientX - startPan.x;
    const dy = e.clientY - startPan.y;
    setStartPan({ x: e.clientX, y: e.clientY });
    setCurrentOffset((prev) => ({ x: prev.x + dx, y: prev.y + dy }));
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const getPinColor = (pin) => {
    switch (pin.dataType) {
      case '360_IMAGE':
        return 'blue';
      default:
        return 'red';
    }
  };

  return (
      <div className={styles['container']}>
        <div className={styles['group-container']} style={{flex: '1', position: 'relative', overflow: 'hidden'}}>
          <div className={styles['left-controls-container']}>
            <PdfPageSelect handlePageChange={handlePageChange} pageNumber={pageNumber} selectedPages={selectedPages}/>
            <ImageTypeSelector activeType={activeType} setActiveType={setActiveType}/>
          </div>
          <div className={styles['zoom-controls']}>
            <button onClick={handleZoomOut} disabled={zoom === minZoom} style={{
              color: zoom === minZoom ? 'grey' : '',
              cursor: zoom === minZoom ? 'not-allowed' : 'pointer',
            }}>-
            </button>
            <button onClick={handleZoomIn} disabled={zoom === maxZoom} style={{
              color: zoom === maxZoom ? 'grey' : '',
              cursor: zoom === maxZoom ? 'not-allowed' : 'pointer',
            }}>+
            </button>
          </div>
          <section
              ref={containerRef}
              style={{
                cursor: isPanning ? 'grabbing' : 'grab',
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              className={styles['canvas-outer-container']}
          >
            <div
                style={{
                  transform: `translate(${currentOffset.x}px, ${currentOffset.y}px) scale(${zoom})`,
                }}
                className={styles['canvas-inner-container']}
            >
              <canvas ref={canvasRef} onDoubleClick={handleCanvasClick}
                      style={{width: '100%', height: '100%'}}/>
              {(pins[pageNumber] || []).filter(pin => pin.dataType.match(activeType)).map((pin, index) => {
                const Icon = pin.dataType === '360_IMAGE' ? WatchIcon : MarkerPinIcon
                return (
                    <Icon
                        className={`${styles['pin']}`}
                        key={index}
                        style={{
                          position: 'absolute',
                          left: `${pin.x * 100}%`,
                          top: `${pin.y * 100}%`,
                          width: '24px',
                          height: '24px',
                          fill: getPinColor(pin),
                          borderRadius: '50%',
                          transform: `translate(-50%, -50%) scale(${1 / zoom})`,
                          cursor: 'pointer',
                          boxShadow: pin === selectedPin ? '0 0 10px 5px rgba(255, 0, 0, 0.8)' : 'none' // glow effect
                        }}
                        onClick={() => handlePinClick(pin, pageNumber)}
                    />
                );
              })}
            </div>
          </section>

          {showModal && selectedPin && (
              <div className={styles.modal} onClick={handleOutsideClick}>
                <div className={styles['modal-content']}>
                  <section className={styles['modal-header']}>
                    <h5 className={styles['modal-title']}>{selectedPin.img.replace(/.*\//, '')}</h5>
                    <button onClick={closeModal} className={styles['close-icon']}>×</button>
                  </section>
                  <div className={styles['modal-image-container']} style={{paddingTop: selectedPin.dataType === '360_IMAGE' ? '0': '75%'}}>
                    {pins[pageNumber]?.length > 1 &&
                        <button className={`${styles.arrow} ${styles['left-arrow']}`} onClick={() => navigateImages('prev')}>{getSvg('chevron-left')}</button>}
                    {selectedPin.dataType === 'STILL_IMAGE' && <img src={selectedPin.img} alt={selectedPin.img}/>}
                    {selectedPin.dataType === '360_IMAGE' && <PannablePhoto src={selectedPin.img}  alt={selectedPin.img} />}
                    {pins[pageNumber]?.length > 1 &&
                        <button className={`${styles.arrow} ${styles['right-arrow']}`} onClick={() => navigateImages('next')}>{getSvg('chevron-right')}</button>}
                  </div>
                  {viewType === 'edit' && <button className={styles['delete-button']} onClick={handleRemovePin}>Delete Marker</button>}
                </div>
              </div>
          )}
        </div>
      </div>
  );
};